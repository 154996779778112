#Thanks {
    min-height: calc(100vh - 700px);
    font-family: bebasFont;
    font-size: 22px;
    max-width: 300px;
    margin: 100px auto;
    text-align: center;
}
#Thanks div {
    padding: 10px 0;
}