.Car {
    width: 270px;
    height: 370px;
    border: 2px solid black;
    border-radius: 20px;
    font-family: bebasFont;
    display: inline-block;
    margin: 10px 13px;
    position: relative; /* seems unnecessary but it stops .Car__ribbon of going out of the area due to its absolute: position */
}
.Car span {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 150px;
    height: 150px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.Car span span {
    position: absolute;
    left: -60px;
    top: 50px;
    width: 250px;
    height: 27px;
    transform: rotate(-45deg);
    transform: rotate(-45deg) translateY(-20px);
    letter-spacing: 0.02em;
}
/* Little shadows of ribbon */
.Car .container-ribbon--green::after,
.Car .container-ribbon--yellow::after,
.Car .container-ribbon--red::after {
    content: ''; /* must be to show color */
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 30.5px;
    left: 0;
    z-index: -1;
}
.Car .container-ribbon--green::after {
    background-color: hsl(120, 100%, 15%);
    box-shadow: 109.5px -110px hsl(120, 100%, 15%); /* Line responsible for the second shadow (the one higher) */
}
.Car .container-ribbon--yellow::after {
    background-color: hsl(39, 100%, 40%);
    box-shadow: 109.5px -110px hsl(39, 100%, 40%); /* Line responsible for the second shadow (the one higher) */
}
.Car .container-ribbon--red::after {
    background-color: hsl(0, 100%, 40%);
    box-shadow: 109.5px -110px hsl(0, 100%, 40%); /* Line responsible for the second shadow (the one higher) */
}
.Car .ribbon--green {
    background: green;
}
.Car .ribbon--yellow {
    background: orange;
}
.Car .ribbon--red {
    background: red;
}
.Car img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: cover;
}
.Car__title {
    margin: 4px 0 5px;
    padding: 0 3px;

    height: 54px;
    line-height: 54px;
    text-align: center;
}
.Car__title strong {
    display: inline-block;
    vertical-align: middle;
    line-height: 27px;
    font-size: 24px;
    font-weight: 100;
}

.Car__description {
    width: 170px;
    height: 60px;
    margin: 0 auto;
}
.Car__description div {
    width: 70px;
    display: inline-block;
    height: 30px;
}
/* In case of "Benzyna + LPG" description the 4th div needs more space, so that's why the code below */
.Car__description div:nth-child(even) {
    position: relative;
    width: 100px;
}
.Car__description__icons {
    max-width: 20px;
    max-height: 16px;
    margin-right: 6px;
}
.Car__button {
    width: 97px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.03em;
    /* Styling above added because <p> alement substituted a <button> for Safari Browsers */
    font-size: 20px;
    font-family: bebasFont;
    border: 2px solid black;
    border-radius: 5px;
    display: block;
    margin: 0px auto;
    background-color: white;
    color: black;
    transition-property: color;
    transition-property: background-color;
    transition-duration: 1s;
    text-decoration: none;
    cursor: pointer;
}
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}
.Car__button:hover {
    background-color: black;
    color: white;
}
