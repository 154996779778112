#Buy__area h3 {
    text-align: center;
    font-size: 21pt;
    margin-bottom: 20px;
    font-weight: 700;
}
#third-span__attachments,
#third-span__attachments input {
    margin: 10px 0px;
}
#third-span button {
    width: 100%;
}
#About {white-space: 'pre-wrap';}
.MapContact {width: 100%; height: 300px; margin: 20px auto 0 auto; border-radius: 10px; }
