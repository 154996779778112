/* *{border: 1px solid red} */
#wallpaper {
    height: 350px;
    width: 100%;
    display: block;
    position: relative;
    /* transition: background-image 800ms linear; */
}

.wallpaper__img {
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    /* transition: opacity 800ms linear; */
    /* transition: background-image 800ms linear; */
}

.wallpaper__img-shown {
    transition: opacity 1500ms linear;
    opacity: 1;
}

.wallpaper__img-hidden {
    transition: opacity 1500ms linear;
    opacity: 0;
}