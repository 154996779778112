/* Setting Segoe font for Safari */
@font-face {
    font-family: SegoeUI;
    src: url(../fonts/SegoeUI.ttf);
}
.SiteHeader {
    font-family: 'Segoe UI', SegoeUI, Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 17px;
    width: 1200px;
    margin: 0 auto;
    padding: 0;
}
.SiteHeader__name {
    width: 800px;
    display: inline-block;
    padding-left: 55px;
}
.SiteHeader__name h2 {
    margin: 10px 0 0;
    padding-left: 20px;
    font-family: bebasFont;
    font-weight: 100;
    line-height: 35px;
    font-size: 32px;
}
.SiteHeader__name p {
    margin: 0;
    font-family: bebasFont;
    font-weight: 100;
    font-size: 18px;
}
.SiteHeader__navbar {
    list-style-type: none;
    width: 100%;
    margin: 0;
    display: inline-block;
}
.SiteHeader__navbar a {
    margin: 5px 16px 10px;
}
.SiteHeader__navbar li {
    display: inline-block;
    text-decoration: none;
    color: black;
}
.SiteHeader__navbar li span:hover {
    color: rgb(208, 208, 208);
    transition: color 0.5s;
}
.SiteHeader .active li span {
    color: rgb(208, 208, 208);
}
.SiteHeader__phoneData {
    width: 180px;
    display: inline-block;
    float: right;
    position: relative;
    bottom: 10px;
}
.SiteHeader__navbar {
    height: 20px;
    text-align: center;
}
.SiteHeader__phoneData img {
    height: 15px;
    margin:  auto;
    display: inline-block;
    position: relative;
    top: 1px;
}
.SiteHeader__phoneData p {
    display: inline-block;
    margin-left: 5px;
}

#SiteHeader__menuBtn {
    display: none;
}

/* Traditional navbar is replaced by menu button */
@media (max-width: 1200px) {
    .SiteHeader {
        width: 100%;
    }
    .SiteHeader__navbar {
        padding: 0;
    }
}
@media (max-width: 990px) {
    .SiteHeader__name {
        width: 450px;
        margin-bottom: 5px;
    }
    .SiteHeader__navbar {
        position: absolute;
        width: 250px;
        top: 73px;
        right: 0;
        z-index: 2;
    }
    .SiteHeader__navbar a {
        margin: 13px 0;
    }
    .SiteHeader__navbar li {
        background-color: rgb(255, 255, 255);
        width: 100%;
        text-align: center;
        z-index: 2;
        padding: 13px 0;
    }
    .SiteHeader__navbar li {
        background-color: rgb(255, 255, 255);
    }
    .SiteHeader__navbar li:hover {
        background-color: rgb(229, 229, 229);
        transition: color 0.5s;
    }
    .SiteHeader__navbar :nth-child(6) li {
        border-bottom-left-radius: 20px;
    }
    .SiteHeader__phoneData {
        margin: 10px 5px 0 0;
    }
    #SiteHeader__menuBtn {
        height: 18px;
        width: 18px;
        display: inline-block;
        margin: 20px 26px 0 20px;
        float: right;
        cursor: pointer;
    }
    .SiteHeader__navbar.SiteHeader__navbar--shown {
        opacity: 0.95;
        visibility: visible;
        transform: translateY(0);
        transition: 500ms ease-in-out;
    }
    .SiteHeader__navbar.SiteHeader__navbar--hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-30px);
        transition: 500ms ease-in-out;
    }
}
@media (max-width: 900px) {
    .SiteHeader__phoneData {
        display: none;
    }
}
@media (max-width: 600px) {
    .SiteHeader__name {
        width: 100%;
        padding: 0;
    }
    .SiteHeader__name h2,
    .SiteHeader__name p {
        padding: 0;
        text-align: center;
    }
    #SiteHeader__menuBtn {
        display: block;
        float: none;
        margin: 0px auto 15px;
    }
    .SiteHeader__navbar {
        top: 101px;
    }
    .SiteHeader__navbar {
        top: 101px;
        width: 100%;
    }
    .SiteHeader__navbar :nth-child(6) li {
        border-bottom-left-radius: 0;
    }
}

@media (max-width: 475px) {
    .SiteHeader__name {
        display: block;
        max-width: 250px;
        margin: 5px auto;
        padding: 0 5px;
    }
    .SiteHeader__navbar {
        top: 111px;
    }
}
.ribbon--green {display: none}
.logo-header {
    width: 25%;
}
